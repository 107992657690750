export const siteName = "Essen JET";
export const siteNameUpper = "ESSEN JET";
export const googleStoreUrl =
  "https://play.google.com/store/apps/details?id=com.adadijital.essenjet&hl=tr";
export const appleStoreUrl =
  "https://apps.apple.com/tr/app/essen-jet/id1518099758";
export const siteMailList = [
  {
    mail: "destek@essenjet.com",
    subject: "Bize Mail Bırakın",
  },
];
export const sitePhoneList = [
  { phone: "0 (555) 156 54 01", info: "(Ramada Şubesi)" },
  { phone: "0 (555) 192 54 01", info: "(Korucuk Şubesi)" },
];
export const eklenenMiktarKatsayisi = 0.1;
/* ============================================================================================== */
/*                            appstora yonlendirme mesaji ile ilgili                              */
/* ============================================================================================== */
export const askPlatformMessage = true; // yonlendirme sorulacak mi
export const askPlatformCounter = 5; // her kac giriste sorulacak (0 ve 1 icin surekli sorar)
export const useAskNeverButton = false; // bir daha sorma butonu aktif mi
/* ============================================================================================== */
/*                                  kategori side bar ile ilgili                                  */
/* ============================================================================================== */
export const categorySideBarType = 1; //1 yeni versiyon A101 ornegi, degilse orjinal versiyon
/* ============================================================================================== */
/*                                        harita ile ilgili                                       */
/* ============================================================================================== */
export const mapCoordinate = {
  lat: 40.76194,
  lng: 30.3927,
};
export const mapZoom = 13;
